/* Variables */
/* COLORBOX POP-UP */
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.75;
  filter: Alpha(Opacity=75);
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: hidden !important;
}

#cboxLoadedContent iframe {
  display: block;
  border: 0;
}

#cboxTitle {
  margin: 0;
  display: none !important;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
}

#cboxClose {
  display: none !important;
}

#colorbox {
  background: white;
  border: 2px solid #ccc;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 0px 27px 6px rgba(66, 66, 66, 0.8);
  -moz-box-shadow: 0px 0px 27px 6px rgba(66, 66, 66, 0.8);
  box-shadow: 0px 0px 27px 6px rgba(66, 66, 66, 0.8);
}

#cboxContent {
  padding: 0;
}

#cboxLoadedContent {
  margin: 0;
}

#cboxLoadingOverlay {
  background: transparent;
}

.shift {
  position: absolute !important;
  left: -10000px !important;
}

.BookReader, .BRmobileMenu, .BRfloat {
  /* Shared root element rules */
  font-family: "Helvetica Neue", Arial, Verdana, sans-serif;
  font-size: 14px;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
  /* Some helper functional css utilities  */
  /* Desktop-only */
  /* Element-level rules */
}
.BookReader .larger, .BRmobileMenu .larger, .BRfloat .larger {
  font-size: 1.2em;
}
@media (min-width: 801px) {
  .BookReader .mv20-lg, .BRmobileMenu .mv20-lg, .BRfloat .mv20-lg {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.BookReader h3, .BRmobileMenu h3, .BRfloat h3 {
  font-size: 20px;
  font-weight: 700;
  color: #dedede;
}
.BookReader a, .BRmobileMenu a, .BRfloat a {
  outline: none;
}
.BookReader button, .BRmobileMenu button, .BRfloat button {
  cursor: pointer;
}
.BookReader a.logo, .BRmobileMenu a.logo, .BRfloat a.logo {
  display: inline-block;
  width: auto;
  height: 100%;
  margin: 0 10px;
  background: transparent url(images/icon_home.svg) no-repeat center center;
  background-size: contain;
}

.BookReader {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  background-color: rgb(169, 204, 169);
}

.BRcontainer {
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  position: absolute;
  -webkit-overflow-scrolling: touch;
}

.BRprogresspopup {
  margin: 80px auto auto;
  position: relative;
  width: 320px;
  background-color: white;
  font-size: 1.5em;
  padding: 20px;
  border: 2px solid #999;
  z-index: 3000;
  color: #222;
}
.BRprogresspopup .close-popup {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  width: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.BRprogresspopup .close-popup .icon.icon-close-dark {
  position: absolute;
  top: 5px;
  right: 5px;
}

.BRprogressbar {
  background-image: url("images/progressbar.gif");
  background-repeat: no-repeat;
  background-position: center top;
}

/* Share dialog */
.BRshare.BRfloat {
  width: 600px;
}
.BRshare .share-title {
  margin: 15px 15px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.BRshare .share-social {
  margin: 15px 15px 0;
}
.BRshare .share-social button {
  padding: 0 5px;
  height: 40px;
  text-align: left;
  color: white;
}
.BRshare .share-social button > i {
  margin-right: 5px;
}
.BRshare .share-social > div {
  display: inline;
}
.BRshare .share-embed {
  margin-top: 20px;
}
.BRshare label.sub.open-to-this-page {
  display: block;
}
.BRshare button[type=button].share-finished {
  background-color: #4a90e2;
  border: 0;
  color: white;
  border-radius: 3px;
  padding: 10px 25px;
  font-size: 15px;
}

/* Info dialog */
.BRinfoValueWrapper {
  margin-bottom: 10px;
}

.BRinfoValue {
  font-size: 16px;
}

.BRinfoLabel {
  font-size: 12px;
  color: #999;
}

.BRinfoMoreInfo {
  background-color: rgb(124, 35, 77);
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  color: white;
}
/* .BRinfoMoreInfo:active {
  background-color: rgb(124, 35, 77);
} */

.BRinfoOtherFormats {
  margin-bottom: 40px;
}

.BRinfoRightCol > div {
  margin-bottom: 0px;
}

.BRinfoFooter {
  text-align: right;
}

/* Fullscreen rules */
.BookReader.fullscreenActive {
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: black;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 2000;
}
.BookReader.fullscreenActive .BRmobileHamburgerWrapper {
  z-index: 2002;
}

body.BRfullscreenActive {
  overflow: hidden;
}
body.BRfullscreenActive .mm-menu {
  z-index: 1999;
}

.BRaction {
  margin-left: 5px;
  margin-right: 5px;
  padding: 8px 30px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  line-height: 1;
}
.BRaction:active {
  background-color: #ababab;
}
.BRaction.default {
  background-color: #c1c1c1;
  color: white;
}
.BRaction.primary {
  background-color: #4990E2;
}
.BRaction.primary:active {
  background-color: #2a76cd;
}
.BRaction.orangeButton {
  background-color: transparent;
  border: 2px solid orange;
  color: orange;
}
.BRaction.orangeButton:active {
  border-color: #f9c613;
}
.BRaction.skinny {
  min-width: 50px;
}
.BRaction.facebook-share-button {
  background-color: #3a5998;
}
.BRaction.twitter-share-button {
  background-color: #429aff;
}
.BRaction.email-share-button {
  background-color: #c1c1c1;
}

/* Mobile Only */
@media (max-width: 800px) {
  .BRinfoLeftCol {
    font-size: 14px;
  }

  .BRimageW {
    text-align: center;
    margin-bottom: 10px;
  }

  .BRimageW img {
    height: 200px;
    width: auto;
  }

  .BRinfoValue.larger {
    font-size: 16px;
  }

  .BRinfoMoreInfoWrapper a {
    font-size: inherit;
    color: white;
    text-decoration: none;
  }

  .BRinfoOtherFormatsFormat {
    margin-bottom: 6px;
  }

  .BRinfoFooter {
    margin-bottom: 10px;
    text-align: left;
  }

  .BRinfoFooter a {
    display: block;
  }

  .BRfloatFoot.BRinfoFooter {
    margin: initial;
    padding: initial;
    border: initial;
    font-size: inherit;
  }

  .BookReader .fieldset-embed, .BRmobileMenu .fieldset-embed, .BRfloat .fieldset-embed {
    display: none;
  }
  .BookReader .BRaction, .BRmobileMenu .BRaction, .BRfloat .BRaction {
    padding: 8px 10px;
    margin-left: 0;
    margin-bottom: 10px;
  }
}
/* Desktop Only */
@media (min-width: 801px) {
  .BRinfoW {
    font-size: 14px;
    position: relative;
    padding: 15px;
  }

  .BRinfoLeftCol {
    float: left;
    width: 40%;
    min-height: 1px;
  }

  .BRinfoRightCol {
    float: left;
    width: 58%;
  }

  .BRimageW {
    text-align: center;
    margin-bottom: 10px;
    margin-right: 26px;
  }

  .BRimageW img {
    height: auto;
    width: 100%;
  }

  .BRinfoValue.larger {
    font-size: 20px;
    line-height: 1.2;
  }

  .BRinfoMoreInfoWrapper a {
    font-size: 14px;
    color: white;
    text-decoration: none;
    font-weight: bold;
  }

  .BRinfoOtherFormatsFormat {
    display: inline;
    margin-right: 10px;
  }

  .BRinfoFooter {
    font-size: 1em;
    clear: both;
  }

  .BRinfoFooter a {
    display: inline;
    margin-right: 10px;
  }

  .BookReader .BRaction, .BRmobileMenu .BRaction, .BRfloat .BRaction {
    padding: 8px 16px;
  }
}
/* filters */
.filter-applied .BRpagediv1up {
  background-color: white;
}
.filter-applied .BRpagedivthumb {
  background-color: white;
}

.BRpageview {
  position: relative;
  margin: auto;
}

.BRmode1up .BRpagecontainer, .BRmodeThumb .BRpagecontainer {
  box-shadow: 1px 1px 2px #333;
}
.BRmode1up .BRpagecontainer img, .BRmodeThumb .BRpagecontainer img {
  border: 0;
}

.BRmodeThumb .BRpagecontainer, .BRmode2Up .BRpagecontainer {
  cursor: pointer;
}

.BRpagecontainer.BRemptypage {
  cursor: unset;
}

.BRpagecontainer {
  position: relative;
  overflow: hidden;
  background: #FEFDEB;
}
.BRpagecontainer img {
  position: absolute;
  background: transparent;
  display: block;
  width: 100%;
  height: 100%;
}
.BRpagecontainer .BRscreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.BRpagecontainer.BRpageloading img {
  color: transparent;
}
.BRpagecontainer.BRemptypage {
  background: transparent;
}
.BRpagecontainer.BRemptypage img {
  background-image: none;
}

svg.BRPageLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.BRpageFlipping .BRPageLayer {
  display: none;
}

.BRbookcover {
  position: absolute;
  background-image: none;
  box-shadow: 1px 0 10px #111;
}

.BRPreview .BRpageimage {
  background-color: #eee;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.BRleafEdgeR {
  border-style: solid solid solid none;
  border-color: #333322;
  border-width: 1px 1px 1px 0px;
  background: transparent url(images/back_pages.png) repeat scroll 0% 0%;
  position: absolute;
  cursor: pointer;
}

.BRleafEdgeL {
  /* border-style: solid none solid solid;
  border-color: rgb(51, 51, 34);
  border-width: 1px 0px 1px 1px; */
  background: transparent url(images/back_pages.png) repeat scroll 0% 0%;
  /* XXXmang replace file */
  position: absolute;
  cursor: pointer;
}

.BRleafEdgeTmp {
  /* border-style: solid none solid solid;
  border-color: rgb(51, 51, 34);
  border-width: 1px 0px 1px 1px; */
  background: transparent url(images/back_pages.png) repeat scroll 0% 0%;
  /* XXXmang replace file */
  position: absolute;
}

.BRgutter {
  /* border: 1px solid rgb(68, 25, 17); */
  position: absolute;
  background: transparent url(images/booksplit.png) repeat scroll 0% 0%;
}

.BRtwoPagePopUp {
  padding: 6px;
  position: absolute;
  font-size: 12px;
  color: white;
  background-color: #9A9B9D;
  opacity: 0.85;
  border-radius: 4px;
  white-space: nowrap;
}

.BRToolbarButton, .BRicon {
  position: relative;
  transition-duration: 0.2s;
  transition-property: background-color, border;
}
.BRToolbarButton:hover, .BRicon:hover {
  background-color: rgba(50, 50, 50, 0.2);
}
.BRToolbarButton:active, .BRicon:active {
  background-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(50, 50, 50, 0.4);
}

.BRicon {
  padding: 0;
  margin: 0;
  vertical-align: middle;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url(images/BRicons.svg);
  background-repeat: no-repeat;
}

i.BRicon {
  display: inline-block;
}

.BRicon.logo {
  width: 34px;
  height: 34px;
  background-position: -3px center;
}
@media (max-width: 800px) {
  .BRicon.logo {
    width: 40px;
    height: 40px;
    background-position: 0px center;
  }
}

.BRicon.info {
  width: 34px;
  height: 34px;
  background-position: -43px center;
}
@media (max-width: 800px) {
  .BRicon.info {
    width: 40px;
    height: 40px;
    background-position: -40px center;
  }
}

.BRicon.share {
  width: 34px;
  height: 34px;
  background-position: -123px center;
}
@media (max-width: 800px) {
  .BRicon.share {
    width: 40px;
    height: 40px;
    background-position: -120px center;
  }
}

.BRicon.read {
  width: 34px;
  height: 34px;
  background-position: -203px center;
  transition-property: background-color, border, background-position;
}
@media (max-width: 800px) {
  .BRicon.read {
    width: 40px;
    height: 40px;
    background-position: -200px center;
  }
}

.BRicon.unread {
  width: 34px;
  height: 34px;
  background-position: -283px center;
  transition-property: background-color, border, background-position;
}
@media (max-width: 800px) {
  .BRicon.unread {
    width: 40px;
    height: 40px;
    background-position: -280px center;
  }
}

.BRicon.fit {
  width: 34px;
  height: 34px;
  background-position: -363px center;
}
@media (max-width: 800px) {
  .BRicon.fit {
    width: 40px;
    height: 40px;
    background-position: -360px center;
  }
}

.BRicon.download {
  width: 34px;
  height: 34px;
  background-position: -1523px center;
}
@media (max-width: 800px) {
  .BRicon.download {
    width: 40px;
    height: 40px;
    background-position: -1520px center;
  }
}

.BRicon.return {
  width: 34px;
  height: 34px;
  background-position: -1043px center;
}
@media (max-width: 800px) {
  .BRicon.return {
    width: 40px;
    height: 40px;
    background-position: -1040px center;
  }
}

.BRicon.fb {
  width: 34px;
  height: 34px;
  background-position: -1603px center;
}
@media (max-width: 800px) {
  .BRicon.fb {
    width: 40px;
    height: 40px;
    background-position: -1600px center;
  }
}

.BRicon.twitter {
  width: 34px;
  height: 34px;
  background-position: -1643px center;
}
@media (max-width: 800px) {
  .BRicon.twitter {
    width: 40px;
    height: 40px;
    background-position: -1640px center;
  }
}

.BRicon.email {
  width: 34px;
  height: 34px;
  background-position: -1683px center;
}
@media (max-width: 800px) {
  .BRicon.email {
    width: 40px;
    height: 40px;
    background-position: -1680px center;
  }
}

/**
 * BRfloat is the modal popup used in bookreader
 */
.BRfloat {
  color: #343434;
}

.BRfloat a.title {
  text-decoration: none;
}

.BRfloat .floatShut {
  position: absolute;
  top: 4px;
  right: 8px;
  display: block;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  background-image: url("images/icon_close-pop.png");
  background-position: 0 0;
  background-repeat: no-repeat;
}

.BRfloat .floatShut:hover {
  background-position: 0 -32px;
}

.BRfloat fieldset {
  padding: 10px 15px;
  border: none;
}

.BRfloat fieldset.sub {
  margin-top: 0px;
  padding: 10px;
}

.BRfloat fieldset.center {
  text-align: center;
  padding: 10px 20px 30px;
}

.BRfloat label {
  display: block;
  font-weight: 700;
  font-size: 1em;
  margin: 5px 0;
}

.BRfloat label.sub {
  display: inline;
  padding: 10px 30px 10px 0;
  font-weight: normal;
  font-size: 1em;
  color: #666;
}

.BRfloat input[type=text],
.BRfloat textarea {
  display: block;
  margin-top: 10px;
  width: 100%;
  padding: 3px;
  border: 2px inset;
  font-family: "Lucida Grande", "Verdana", "Arial", sans-serif;
  font-size: 0.8em;
  line-height: 1.25;
  font-weight: normal;
}

.BRfloat textarea {
  height: 55px;
}

.BRfloat p {
  margin: 15px 15px 0;
}

.BRfloat p.meta {
  font-size: 1.1em;
  color: #748d36;
  margin: 10px 0 0;
}

.BRfloatCover {
  float: left;
  padding: 0 20px 30px 0;
}

.BRfloatHead {
  font-size: 1.2em;
}

.BRfloatHead h2 {
  display: inline;
  font-size: 1.3em;
}

.BRfloatMeta p {
  margin: 0;
  padding: 0;
  font-size: 1.1em;
  line-height: 1.5em;
}

.BRfloatMeta p.moreInfo {
  line-height: 15px;
  margin-top: 30px;
}

.BRfloatMeta p.moreInfo span {
  background: url("images/icon_OL-logo-xs.png") no-repeat;
  display: block;
  float: left;
  width: 26px;
  height: 15px;
}

.BRfloatMeta h3 {
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.5em;
  margin-top: 30px;
  color: #333;
}

.BRfloatMeta ul.links {
  float: left;
  clear: right;
}

.BRfloatMeta ul.links li {
  list-style-type: none;
  display: block;
  float: left;
  font-size: 1.1em;
  line-height: 1.5em;
}

.BRfloatMeta ul.links li span {
  padding: 0 10px;
}

.BRfloatFoot a.problem, a.problem-icon {
  background: url("images/icon_alert-xs.png") no-repeat;
  padding-left: 20px;
}

.BRfloatHead,
.BRfloatBody {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

/* Desktop Only */
@media (min-width: 801px) {
  .BRfloat .message,
.BRfloatBody {
    padding: 10px 15px 10px 15px;
    font-size: 16px;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0px;
  }

  .BRfloatHead {
    box-sizing: border-box;
    line-height: normal;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
  }

  .BRfloatFoot {
    padding: 10px;
    margin-bottom: 10px;
  }
}
/* Mobile Only (with some responsiveness) */
@media (max-width: 800px) {
  .BRfloatBody {
    padding: 10px 10px;
  }

  .BRfloat .message,
.BRfloatBody,
.BRfloatHead {
    box-sizing: border-box;
    line-height: normal;
    border-bottom: 1px solid #d8d8d8;
    padding: 10px;
    min-height: initial;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  .BRfloatFoot {
    padding: 10px 10px 0 10px;
    border-top: 1px solid #ccc;
  }

  .BRfloatFoot .BRaction {
    margin-bottom: 10px;
  }
}
@media (min-width: 801px) {
  .BRfloat {
    max-width: initial;
    width: 455px;
  }

  .BRfloat.wide {
    max-width: initial;
    width: 550px;
  }
}
@media (max-width: 800px) and (min-width: 341px) {
  .BRfloat {
    max-width: initial;
    width: 340px;
  }
}
@media (max-width: 340px) {
  .BRfloat {
    max-width: 95%;
    width: 95%;
  }
}
/**
 * BRnav is the bottom control bar (eg footer)
 */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.BRfooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* important so it overlays over the book pages when showing */
  z-index: 3;
}
.BRfooter button {
  width: 30px;
  height: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  background: transparent;
  outline: none;
}
.BRfooter button:hover .tooltip {
  display: block;
}
.BRfooter button.hide {
  display: block;
}

.hide{
  display: none;
}

.BRReadAloudToolbar {
  display: flex;
  justify-content: center;
  margin: 5px;
  padding: 0 8.5px;
  border-radius: 4px;
  align-self: center;
  height: 34px;
  animation: fadeUp 0.2s;
  background-color: #333;
  color: white;
  /* Full mobile styles */
}
.BRReadAloudToolbar a {
  color: white;
}
.BRReadAloudToolbar .BRpager {
  background-color: #4D4D4D;
}
.BRReadAloudToolbar .BRpager .ui-slider-handle {
  background: white;
  border: none;
}
.BRReadAloudToolbar .BRpager .ui-slider-range {
  background: rgba(255, 255, 255, 0.59);
}
.BRReadAloudToolbar .BRpage .BRicon {
  border-left: 1px solid #4D4D4D;
}
.BRReadAloudToolbar .BRpage .BRicon.activated {
  background-color: rgba(255, 255, 255, 0.4);
}
.BRReadAloudToolbar .BRpage .BRicon.activated:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: block;
  border: 3px solid rgba(255, 255, 255, 0.3);
}
.BRReadAloudToolbar .BRnavCntl {
  z-index: 6;
  background-color: #4D4D4D;
}
.BRReadAloudToolbar .BRnavCntlBtm:hover {
  background-color: #0074D1;
}
.BRReadAloudToolbar.BRnavEmbed {
  background-color: white;
  color: black;
}
.BRReadAloudToolbar.BRnavEmbed a {
  color: black;
}
.BRReadAloudToolbar.BRnavEmbed .BRpager.ui-slider {
  background: #B7B7B7;
}
.BRReadAloudToolbar.BRnavEmbed .BRpager.ui-slider .ui-slider-handle {
  background: #0074D1;
}
.BRReadAloudToolbar.BRnavEmbed .BRpager.ui-slider .ui-slider-range {
  background: rgba(0, 116, 209, 0.25);
}
.BRReadAloudToolbar.BRnavEmbed .BRpage .BRicon {
  border-left: 1px solid #979797;
}
.BRReadAloudToolbar.BRnavEmbed .BRpage .BRicon.activated {
  background-color: rgba(0, 116, 209, 0.4);
}
.BRReadAloudToolbar.BRnavEmbed .BRpage .BRicon.activated:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: block;
  border: 3px solid rgba(0, 116, 209, 0.3);
}
.BRReadAloudToolbar.BRnavEmbed .BRnavCntl {
  background-color: #979797;
}
.BRReadAloudToolbar.BRnavEmbed .BRnavCntlBtm:hover {
  background-color: #0074D1;
}
@media (max-width: 800px) {
  .BRbodyMobileNavEnabled .BRReadAloudToolbar, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar {
    background-color: white;
    color: black;
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar a, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar a {
    color: black;
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRpager.ui-slider, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRpager.ui-slider {
    background: #B7B7B7;
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRpager.ui-slider .ui-slider-handle, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRpager.ui-slider .ui-slider-handle {
    background: #0074D1;
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRpager.ui-slider .ui-slider-range, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRpager.ui-slider .ui-slider-range {
    background: rgba(0, 116, 209, 0.25);
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRpage .BRicon, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRpage .BRicon {
    border-left: 1px solid #979797;
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRpage .BRicon.activated, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRpage .BRicon.activated {
    background-color: rgba(0, 116, 209, 0.4);
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRpage .BRicon.activated:before, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRpage .BRicon.activated:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: block;
    border: 3px solid rgba(0, 116, 209, 0.3);
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRnavCntl, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRnavCntl {
    background-color: #979797;
  }
  .BRbodyMobileNavEnabled .BRReadAloudToolbar .BRnavCntlBtm:hover, .BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRReadAloudToolbar .BRnavCntlBtm:hover {
    background-color: #0074D1;
  }
}
.BRReadAloudToolbar--controls {
  display: flex;
}
.BRReadAloudToolbar--controls > * {
  height: 100%;
}
.BRReadAloudToolbar .BRToolbarButton .icon {
  width: 34px;
  height: 34px;
  box-sizing: border-box;
}
.BRReadAloudToolbar .BRToolbarButton.jumpForward .icon, .BRReadAloudToolbar .BRToolbarButton.jumpBackward .icon {
  padding: 9px;
}
.BRReadAloudToolbar button {
  padding: 0;
  margin: 0;
  border: 0px none;
  box-sizing: border-box;
  background: transparent none repeat scroll 0% 0%;
  display: block;
}
.BRReadAloudToolbar .playback-rate-container {
  width: 34px;
  height: 34px;
  position: relative;
}
.BRReadAloudToolbar .playback-rate-container .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  padding-bottom: 1em;
}
.BRReadAloudToolbar .playback-rate-container select {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.8em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  text-align: center;
  text-align-last: center;
  /* Chrome */
  padding-top: 60%;
  padding-left: 10%;
  padding-right: 10%;
  color: #333;
  background: transparent;
  /* IE */
}
.BRReadAloudToolbar .playback-rate-container select::-ms-expand {
  display: none;
}
.BRReadAloudToolbar button:hover, .BRReadAloudToolbar .playback-rate-container:hover {
  background: rgba(50, 50, 50, 0.2);
}

.BRnav {
  box-sizing: border-box;
  height: 34px;
  padding-top: 0;
  overflow: visible;
  display: block;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #222;
  /* Theming */
  background-color: #333;
  color: white;
  /* Full mobile styles */
}
.BRnav.BRnavEmbed {
  padding-top: 0;
  height: auto;
  align-items: center;
}
.BRnav.BRnavEmbed .BRembedreturn {
  flex: 1 auto;
  overflow-x: hidden;
}
.BRnav.BRnavEmbed .logo {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 0 10px;
  background: transparent url(images/icon_home.svg) no-repeat center center;
  background-size: contain;
}
.BRnav a {
  color: white;
}
.BRnav .BRpager {
  background-color: #4D4D4D;
}
.BRnav .BRpager .ui-slider-handle {
  background: white;
  border: none;
}
.BRnav .BRpager .ui-slider-range {
  background: rgba(255, 255, 255, 0.59);
}
.BRnav .BRpage .BRicon {
  border-left: 1px solid #4D4D4D;
}
.BRnav .BRpage .BRicon.activated {
  background-color: rgba(255, 255, 255, 0.4);
}
.BRnav .BRpage .BRicon.activated:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: block;
  border: 3px solid rgba(255, 255, 255, 0.3);
}
.BRnav .BRnavCntl {
  z-index: 6;
  background-color: #4D4D4D;
}
.BRnav .BRnavCntlBtm:hover {
  background-color: #0074D1;
}
@media (max-width: 800px) {
  .BRnav {
    height: 34px;
    box-shadow: 0px -1px 3px 0px rgba(102, 102, 102, 0.25);
  }
}

.BRnavpos {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  float: left;
  flex: 1 auto;
}

.BRpager.ui-slider {
  position: relative;
  height: 8px;
  top: 13px;
  box-sizing: content-box;
  border-radius: 10px;
  cursor: pointer;
  /* handle active state for touch */
}
.BRpager.ui-slider.ui-widget.ui-widget-content {
  border: none;
}
.BRpager.ui-slider .ui-slider-horizontal .ui-slider-handle,
.BRpager.ui-slider .ui-slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -5px;
  margin-left: -9px;
  /* Center icon */
  z-index: 6;
  border: none;
}
.BRpager.ui-slider .ui-slider-range {
  height: 100%;
  border-radius: 10px;
}
.touch .BRpager.ui-slider .ui-slider-handle.ui-state-active {
  width: 24px;
  height: 24px;
  top: -4px;
  margin-left: -11px;
}

/* BRnavlin is where chapters/search are appended */
.BRnavline {
  position: relative;
  height: 1px;
  width: auto;
  top: -1px;
  margin: 0 10px;
  width: 100%;
}

.BRcurrentpage {
  display: inline-block;
  text-align: center;
  padding: 0 0 0 0;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 10px;
  line-height: 1;
}

.BRnavTitle {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0 0 0 0;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 5px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.5px;
  font-style: italic;
}

.BRpage {
  height: 100%;
  overflow: hidden;
  padding-left: 0;
  text-align: right;
  white-space: nowrap;
  float: left;
  flex: none;
}

.BRpageLparan {
  margin-right: 1px;
  font-size: 0.8em;
  vertical-align: top;
}

.BRpageRparan {
  margin-left: 1px;
  font-size: 0.8em;
  vertical-align: top;
}

.BRup {
  background-image: url("images/nav_control-up.png");
  background-repeat: no-repeat;
}

/* Mobile Only */
@media (max-width: 800px) {
  /* hide navline chapters and search in mobile */
  .BRnavline .BRchapter {
    display: none;
  }

  .BRnavCntlBtm {
    bottom: 34px;
  }

  .BRpager.ui-slider {
    height: 10px;
    top: 12px;
  }
}
/* Mobile nav zoom buttons */
.DrawerSettingsWrapper [class*=zoom] {
  width: 40px;
  height: 40px;
  background-position: -1224px center;
  background-size: 3440px auto;
}

.DrawerSettingsWrapper .zoom_in {
  background-position: -1384px center;
}

/**
 * BRtoolbar is the top control bar (eg header)
 */
.BRtoolbar {
  position: relative;
  top: 0;
  left: 0;
  padding: 10px 10px 4px 10px;
  width: 100%;
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  color: white;
  font-size: 14px;
  /* ensure that this displays over book */
  z-index: 3;
}

.BRtoolbar .label {
  font-size: 12px;
  color: #ccc;
}

.BRtoolbar a {
  text-decoration: none;
  color: white;
}
.BRtoolbar a:hover {
  color: white;
  text-decoration: underline;
}

/* desktop header */
.BRtoolbarSection > * {
  margin-left: 6px;
}

.BRtoolbarbuttons {
  white-space: nowrap;
  text-align: right;
  border-spacing: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.BRtoolbarbuttons .BRpill {
  border: 1px solid black;
  background: rgb(124, 35, 77);
  padding: 5px 10px;
  height: 30px;
  border-radius: 20px;
  color: white;
  line-height: 0;
  vertical-align: middle;
  font-size: 12px;
  cursor: pointer;
  margin-right: 1vw;
}
.BRtoolbarbuttons .BRpill.BRtoolbarHamburger {
  border: none;
  border-radius: none;
}

.BRtoolbarSection {
  white-space: nowrap;
}

.BRtoolbarSectionLogo {
  text-align: center;
  display: none;
}

.BRtoolbarLeft {
  text-align: left;
  flex: 1 auto;
  overflow-y: hidden;
}

.BRtoolbarRight {
  text-align: right;
  flex: none;
}

@media (max-width: 800px) {
  .BRtoolbar {
    height: 34px;
    padding: 0;
    box-shadow: 0px 1px 3px 0px rgba(102, 102, 102, 0.25);
  }
}
.BookReader .BRquery, .BRmobileMenu .BRquery, .BRfloat .BRquery, .BookReader .BRchapter > div, .BRmobileMenu .BRchapter > div, .BRfloat .BRchapter > div {
  display: none;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: 230px;
  padding: 12px 14px;
  color: #fff;
  font-weight: bold;
  background: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.BookReader .BRquery:after, .BRmobileMenu .BRquery:after, .BRfloat .BRquery:after, .BookReader .BRchapter > div:after, .BRmobileMenu .BRchapter > div:after, .BRfloat .BRchapter > div:after {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  border: 7px solid transparent;
  border-width: 7px 4px;
  border-bottom: none;
  border-top-color: #333;
}

.BookReader .searchHiliteLayer, .BookReader .ttsHiliteLayer, .BRmobileMenu .searchHiliteLayer, .BRmobileMenu .ttsHiliteLayer, .BRfloat .searchHiliteLayer, .BRfloat .ttsHiliteLayer {
  pointer-events: none;
}
.BookReader .searchHiliteLayer rect, .BookReader .ttsHiliteLayer rect, .BRmobileMenu .searchHiliteLayer rect, .BRmobileMenu .ttsHiliteLayer rect, .BRfloat .searchHiliteLayer rect, .BRfloat .ttsHiliteLayer rect {
  fill: #0000ff;
  fill-opacity: 0.2;
  animation: hiliteFadeIn 0.2s;
}
.BookReader .BRchapter, .BookReader .BRsearch, .BRmobileMenu .BRchapter, .BRmobileMenu .BRsearch, .BRfloat .BRchapter, .BRfloat .BRsearch {
  position: absolute;
  bottom: 0;
  /* Relative to nav line */
  cursor: pointer;
  z-index: 3;
  background-color: transparent;
  background-repeat: no-repeat;
  font-weight: normal;
}
.BookReader .BRchapter, .BRmobileMenu .BRchapter, .BRfloat .BRchapter {
  background-color: white;
  color: #666;
  height: 8px;
  width: 4px;
  bottom: -13px;
  margin-left: -12px;
  /* Center icon */
}
.BookReader .BRchapter:hover > div, .BRmobileMenu .BRchapter:hover > div, .BRfloat .BRchapter:hover > div {
  display: block;
}
.BookReader .BRchapter.front, .BRmobileMenu .BRchapter.front, .BRfloat .BRchapter.front {
  background-color: blue;
}
.BookReader .BRsearch, .BRmobileMenu .BRsearch, .BRfloat .BRsearch {
  width: 9px;
  height: 6px;
  margin-left: -10px;
}
.BookReader .BRsearch:before, .BRmobileMenu .BRsearch:before, .BRfloat .BRsearch:before {
  position: absolute;
  left: calc(50% - 2px);
  transform: translateX(-50%);
  width: 2px;
  height: 6px;
  content: "";
  background: #428bca;
  border-radius: 2px;
}
.BookReader .BRsearch:hover .BRquery, .BRmobileMenu .BRsearch:hover .BRquery, .BRfloat .BRsearch:hover .BRquery {
  display: block;
}
.BookReader .BRsearch .BRquery, .BRmobileMenu .BRsearch .BRquery, .BRfloat .BRsearch .BRquery {
  display: none;
}
.BookReader .BRquery b, .BRmobileMenu .BRquery b, .BRfloat .BRquery b {
  color: #adaedc;
  font-weight: bold;
  background-color: #272958;
}
.BookReader .BRbooksearch, .BRmobileMenu .BRbooksearch, .BRfloat .BRbooksearch {
  position: relative;
  box-sizing: border-box;
}
.BookReader .BRbooksearch.desktop, .BRmobileMenu .BRbooksearch.desktop, .BRfloat .BRbooksearch.desktop {
  position: relative;
  height: 30px;
  border: 1px solid white;
  border-radius: 20px;
  display: inline-block;
  line-height: 0;
  vertical-align: top;
  margin-top: 0;
  margin-bottom: 0;
}
.BookReader .BRbooksearch.desktop .BRsearchInput, .BRmobileMenu .BRbooksearch.desktop .BRsearchInput, .BRfloat .BRbooksearch.desktop .BRsearchInput {
  -webkit-appearance: none;
  position: relative;
  width: 130px;
  max-width: 160px;
  height: 100%;
  color: white;
  outline: none;
  border: none;
  padding: 3px 0px 3px 10px;
  margin: 0;
  box-sizing: border-box;
  background: transparent;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 1.2;
}
.BookReader .BRbooksearch.desktop .BRsearchSubmit, .BRmobileMenu .BRbooksearch.desktop .BRsearchSubmit, .BRfloat .BRbooksearch.desktop .BRsearchSubmit {
  width: auto;
  height: 100%;
  border: none;
  text-align: center;
  color: #fff;
  overflow: hidden;
  box-sizing: boder-box;
  display: block;
  background: transparent;
  float: left;
  cursor: pointer;
}
.BookReader .BRsearch-navigation, .BRmobileMenu .BRsearch-navigation, .BRfloat .BRsearch-navigation {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #333;
  padding: 5px 0;
}
.BookReader .BRsearch-navigation button.toggle-sidebar, .BRmobileMenu .BRsearch-navigation button.toggle-sidebar, .BRfloat .BRsearch-navigation button.toggle-sidebar {
  width: unset;
  padding: 0 10px;
}
.BookReader .BRsearch-navigation button.toggle-sidebar h4, .BRmobileMenu .BRsearch-navigation button.toggle-sidebar h4, .BRfloat .BRsearch-navigation button.toggle-sidebar h4 {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  color: #fff;
}
.BookReader .BRsearch-navigation button.toggle-sidebar h4 span, .BRmobileMenu .BRsearch-navigation button.toggle-sidebar h4 span, .BRfloat .BRsearch-navigation button.toggle-sidebar h4 span {
  vertical-align: middle;
}
.BookReader .BRsearch-navigation .pagination, .BRmobileMenu .BRsearch-navigation .pagination, .BRfloat .BRsearch-navigation .pagination {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.BookReader .BRsearch-navigation .pagination span, .BRmobileMenu .BRsearch-navigation .pagination span, .BRfloat .BRsearch-navigation .pagination span {
  display: inline-block;
  padding: 0 5px;
}
.BookReader .BRsearch-navigation .pagination button[disabled], .BRmobileMenu .BRsearch-navigation .pagination button[disabled], .BRfloat .BRsearch-navigation .pagination button[disabled] {
  opacity: 0.3;
  cursor: default;
}
.BookReader .BRsearch-navigation .pagination button span, .BRmobileMenu .BRsearch-navigation .pagination button span, .BRfloat .BRsearch-navigation .pagination button span {
  width: 8px;
  height: 13px;
  padding: 0;
  background-size: 100% auto;
}
.BookReader .BRsearch-navigation .clear span, .BRmobileMenu .BRsearch-navigation .clear span, .BRfloat .BRsearch-navigation .clear span {
  width: 18px;
  height: 18px;
}
.BookReader .BRsearch-navigation + .BRnav .BRnavCntl, .BRmobileMenu .BRsearch-navigation + .BRnav .BRnavCntl, .BRfloat .BRsearch-navigation + .BRnav .BRnavCntl {
  display: none;
}

@keyframes hiliteFadeIn {
  from {
    fill-opacity: 0;
  }
}
/* Mid size breakpoint */
@media (max-width: 1050px) {
  .BookReader .BRbooksearch .BRsearchInput, .BRmobileMenu .BRbooksearch .BRsearchInput, .BRfloat .BRbooksearch .BRsearchInput {
    padding: 0 0 0 8px;
    width: 110px;
    min-width: 110px;
  }
  .BookReader .BRbooksearch .BRsearchSubmit, .BRmobileMenu .BRbooksearch .BRsearchSubmit, .BRfloat .BRbooksearch .BRsearchSubmit {
    float: left;
  }
}
.mm-menu, .mm-panels, .mm-panels > .mm-panel {
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  box-sizing: border-box;
}

.mm-btn, .mm-menu {
  box-sizing: border-box;
}

.mm-listview a, .mm-listview a:hover, .mm-navbar a, .mm-navbar a:hover {
  text-decoration: none;
}

.mm-hidden {
  display: none !important;
}

.mm-menu, .mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  padding: 0;
  position: absolute;
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  position: absolute;
}

.mm-btn, .mm-panel.mm-highest {
  z-index: 1;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-panel.mm-opened {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-listview .mm-divider, .mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  content: "";
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical > .mm-next:after, .mm-vertical .mm-listview > li > .mm-next:after {
  top: 16px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 19px;
}

.mm-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: "";
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-arrow:after, .mm-next:after, .mm-prev:before {
  content: "";
  border: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 23px;
}

.mm-arrow:after, .mm-next:after {
  border-top: none;
  border-left: none;
  right: 23px;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar .mm-btn:first-child {
  padding-left: 20px;
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  padding-right: 20px;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a, .mm-listview > li > span {
  color: inherit;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 0;
}

.mm-listview > li > a.mm-arrow, .mm-listview > li > span.mm-arrow {
  padding-right: 50px;
}

.mm-listview > li:not(.mm-divider):after {
  content: "";
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  content: "";
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px;
}

.mm-listview .mm-inset {
  list-style: disc inside;
  padding: 0 10px 15px 40px;
  margin: 0;
}

.mm-listview .mm-inset > li {
  padding: 5px 0;
}

.mm-listview .mm-divider {
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px;
}

.mm-listview .mm-spacer {
  padding-top: 40px;
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px;
}

.mm-menu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu .mm-listview > li .mm-arrow:after, .mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-divider, .mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  z-index: 1;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
}

html.mm-blocking, html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

.mm-menu.mm-offcanvas.mm-current {
  display: block;
}

html.mm-opening .mm-slideout {
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}
@media all and (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}
.mm-navbar-bottom, .mm-navbar-top ~ .mm-navbar-top {
  border-bottom: none;
}

.mm-menu > .mm-navbar {
  background: inherit;
  padding: 0;
  z-index: 3;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-navbar-bottom {
  border-top-width: 1px;
  border-top-style: solid;
  top: auto;
  bottom: 0;
}

.mm-navbar-bottom ~ .mm-navbar-bottom {
  border-top: none;
}

.mm-navbar.mm-hasbtns {
  padding: 0 40px;
}

.mm-navbar[class*=mm-navbar-content-] > * {
  box-sizing: border-box;
  display: block;
  float: left;
}

.mm-navbar .mm-breadcrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0 0 0 17px;
}

.mm-navbar .mm-breadcrumbs > * {
  display: inline-block;
  padding: 10px 3px;
}

.mm-navbar .mm-breadcrumbs > a {
  text-decoration: underline;
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
  margin-left: -40px;
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
}

.mm-navbar-top-1 {
  top: 0;
}

.mm-hasnavbar-top-1 .mm-panels, .mm-navbar-top-2 {
  top: 40px;
}

.mm-hasnavbar-top-2 .mm-panels, .mm-navbar-top-3 {
  top: 80px;
}

.mm-hasnavbar-top-3 .mm-panels, .mm-navbar-top-4 {
  top: 120px;
}

.mm-hasnavbar-top-4 .mm-panels {
  top: 160px;
}

.mm-navbar-bottom-1 {
  bottom: 0;
}

.mm-hasnavbar-bottom-1 .mm-panels, .mm-navbar-bottom-2 {
  bottom: 40px;
}

.mm-hasnavbar-bottom-2 .mm-panels, .mm-navbar-bottom-3 {
  bottom: 80px;
}

.mm-hasnavbar-bottom-3 .mm-panels, .mm-navbar-bottom-4 {
  bottom: 120px;
}

.mm-hasnavbar-bottom-4 .mm-panels {
  bottom: 160px;
}

.mm-navbar-size-2 {
  height: 80px;
}

.mm-navbar-size-3 {
  height: 120px;
}

.mm-navbar-size-4 {
  height: 160px;
}

.mm-navbar-content-2 > * {
  width: 50%;
}

.mm-navbar-content-3 > * {
  width: 33.33%;
}

.mm-navbar-content-4 > * {
  width: 25%;
}

.mm-navbar-content-5 > * {
  width: 20%;
}

.mm-navbar-content-6 > * {
  width: 16.67%;
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: #fff;
  font-size: 14px;
}

.mm-menu {
  color: #fff;
  background: #151515;
}
.mm-menu > .mm-navbar {
  background: #333;
}

.mm-panel {
  font-size: 12px;
}

#mm-blocker {
  z-index: 2002;
}

.mm-panel h1 {
  margin-top: 0;
  color: #245e83;
  font-size: 14px;
}

.mm-menu .mm-listview > li .mm-next,
.mm-menu .mm-listview > li .mm-next:before, .mm-menu .mm-listview > li:after,
.mm-menu .mm-listview > li .mm-arrow:after,
.mm-menu .mm-listview > li .mm-next:after {
  border-color: #fff;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
  border-color: #fff;
}

/* Needed to override mmenu style */
html.mm-background .BookReader {
  background-color: black;
}

.BRmobileMenu button {
  -webkit-appearance: initial;
}
.BRmobileMenu .BRaction {
  display: block;
  width: 100%;
}
.BRmobileMenu div.share-social button {
  padding: 0 5px;
  height: 40px;
  text-align: left;
}
.BRmobileMenu a {
  color: inherit;
}

.BRtoolbarMobileTitle {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  line-height: 40px;
  display: block;
  margin-right: 10px;
}

.BRmobileHamburgerWrapper {
  display: block;
  padding: 0 30px 0 50px;
}

.BRmobileHamburger {
  background: transparent url("icons/hamburger.svg") 50% 50% no-repeat;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 10px;
  border: none;
  outline: none;
}

.DrawerIconWrapper {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  position: relative;
}

.DrawerIcon {
  height: 18px;
  width: auto;
  position: absolute;
  left: 0;
  top: 1px;
}

.DrawerSettingsTitle {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.DrawerSettingsLayoutWrapper {
  text-align: center;
  padding: 0;
  margin-bottom: 20px;
}

.DrawerLayoutButton {
  border: none;
  background: none;
  color: #fff;
  line-height: 18px;
}

.DrawerLayoutButton:active {
  color: grey;
}

.BRmobileShare div.share-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.BRmobileShare div.share-social {
  margin: 10px 0 20px 0;
}
.BRmobileShare p {
  font-weight: bold;
}
.BRmobileShare fieldset {
  padding: 0;
  border: 0;
  margin-bottom: 30px;
}
.BRmobileShare fieldset label {
  display: block;
}
.BRmobileShare fieldset input[type=text] {
  width: 95%;
}
.BRmobileShare button.share-finished {
  display: none;
}
.BRmobileShare div.share-embed {
  border: 1px solid #ccc;
  padding: 0 10px;
}
.BRmobileShare .open-to-this-page {
  display: block;
  margin: 10px 0;
}

.BRmobileHamburgerWrapper {
  display: none;
}

.BRmobileMenu {
  display: none;
}

.BRtoolbar.responsive {
  display: none;
}

/* Mobile Only */
@media (max-width: 800px) {
  .BRbodyMobileNavEnabled .BRtoolbar.responsive,
.BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRtoolbar.responsive {
    display: block;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
    color: #fff;
    background: #333;
  }
  .BRbodyMobileNavEnabled .BRtoolbar.responsive .BRtoolbarbuttons,
.BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRtoolbar.responsive .BRtoolbarbuttons {
    display: none;
  }
  .BRbodyMobileNavEnabled .BRtoolbar.responsive .BRmobileHamburgerWrapper,
.BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRtoolbar.responsive .BRmobileHamburgerWrapper {
    display: block;
  }
  .BRbodyMobileNavEnabled .BRtoolbar.responsive .BRmobileMenu,
.BRbodyMobileNavEnabledFullscreen.BRfullscreenActive .BRtoolbar.responsive .BRmobileMenu {
    display: block;
  }
}
.BRcheckbox-label-filters {
  position: relative;
  bottom: 2px;
}

/* Desktop Only */
@media (min-width: 801px) {
  .BRtoolbar.responsive {
    display: block;
  }
}
li.BRtable-contents-el {
  line-height: 150%;
  padding: 10px 10px 10px 20px;
  overflow: hidden;
  color: #fff;
}
li.BRtable-contents-el.chapter-clickable {
  font-weight: normal;
}
li.BRtable-contents-el.current-chapter {
  background-color: lightblue;
}
li.BRtable-contents-el > span {
  display: inline;
  padding: 0;
  white-space: normal;
}
li.BRtable-contents-el .BRTOCElementPage {
  font-size: 0.85em;
  opacity: 0.8;
}

/****************** Excerpt from jquery-ui *********************/
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.icon {
  display: inline-block;
  background: transparent 50% 50% no-repeat;
}

.icon.hflip {
  transform: scaleX(-1);
}

.active .icon {
  filter: brightness(0.2);
}

.icon-read-aloud {
  width: 20px;
  height: 16px;
  background-image: url("icons/read-aloud.svg");
}

.icon-onepg {
  width: 11px;
  height: 19px;
  background-image: url("icons/1up.svg");
}

.icon-twopg {
  width: 20px;
  height: 19px;
  background-image: url("icons/2up.svg");
}

.icon-fullscreen,
.icon-fullscreen-exit {
  width: 18px;
  height: 18px;
  background-image: url("icons/fullscreen.svg");
}

.icon-fullscreen-exit {
  background-image: url("icons/fullscreen_exit.svg");
}

.icon-thumb {
  width: 17px;
  height: 19px;
  background-image: url("icons/thumbnails.svg");
}

.icon-playback-speed {
  width: 24px;
  height: 32px;
  background-image: url("icons/playback-speed.svg");
}

.icon-review,
.icon-advance {
  width: 21px;
  height: 19px;
  background-image: url("icons/review.svg");
}

.icon-advance {
  background-image: url("icons/advance.svg");
}

.icon-volume-full {
  width: 23px;
  height: 22px;
  background-image: url("icons/volume-full.svg");
}

.icon-play,
.icon-pause {
  width: 40px;
  height: 40px;
  background-image: url("icons/play.svg");
}

.icon-pause {
  background-image: url("icons/pause.svg");
}

.icon-left-arrow {
  width: 12px;
  height: 14px;
  background-image: url("icons/left-arrow.svg");
}

.icon-magnify {
  position: relative;
  width: 18px;
  height: 18px;
  background-image: url("icons/magnify-minus.svg");
}
.icon-magnify.plus {
  background-image: url("icons/magnify-plus.svg");
}

.icon-search {
  width: 19px;
  height: 19px;
  background-image: url("images/icon_search_button.svg");
}

.icon-chevron {
  width: 5px;
  height: 8px;
  background-image: url("icons/chevron-right.svg");
}

.icon-close {
  width: 12px;
  height: 12px;
  background-image: url("icons/close-circle.svg");
}

.icon-close-dark {
  width: 12px;
  height: 12px;
  background-image: url("icons/close-circle-dark.svg");
}

.BRcontrols {
  width: 100%;
  /* BookReader defaults overrides */
}
.BRcontrols .tooltip {
  display: none;
  position: absolute;
  width: auto;
  left: 50%;
  bottom: calc(100% + 10px);
  transform: translateX(-50%);
  padding: 5px 10px;
  font-weight: bold;
  white-space: nowrap;
  color: #fff;
  border-radius: 3px;
  background: #333;
}
.BRcontrols .full .tooltip {
  left: auto;
  right: 0;
  transform: translateX(0);
}
.BRfullscreenActive .BRcontrols .icon-fullscreen {
  background-image: url("icons/fullscreen_exit.svg");
}
.BRcontrols select.playback-speed {
  width: 24px;
  padding-top: 18px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 10px;
  text-align: center;
  text-align-last: center;
  color: #fff;
  border: none;
  cursor: pointer;
  background: transparent url("icons/playback-speed.svg") 50% 0 no-repeat;
}
.BRcontrols select.playback-speed option {
  background: #333;
}
.BRcontrols .active {
  background: #fff;
}
.BRcontrols li {
  position: relative;
}
.BRcontrols .controls {
  position: relative;
  z-index: 2;
  display: flex;
  padding: 2px 10px;
  margin: 0;
  list-style: none;
  background: #333;
}
@media (max-width: 800px) {
  .BRcontrols .controls {
    padding-left: 0;
  }
}
.BRcontrols .controls button {
  width: 30px;
  height: 30px;
}
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.BRcontrols .read-aloud {
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 5px 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  list-style: none;
  background: #333;
}
.BRcontrols .read-aloud.visible {
  display: flex;
  animation: slideUp 0.2s;
}
.BRcontrols .read-aloud li {
  padding: 0 10px;
}
.BRcontrols .read-aloud [name=play] .icon-pause {
  display: none;
}
.BRcontrols .read-aloud [name=play].playing .icon-play {
  display: none;
}
.BRcontrols .read-aloud [name=play].playing .icon-pause {
  display: inline-block;
}
.BRcontrols .scrubber {
  display: flex;
  width: 100%;
  height: 30px;
}
.BRcontrols .frame {
  position: relative;
  width: 100%;
}
.BRcontrols .color-fill {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 8px;
  border-radius: 8px;
}
.BRcontrols .scrubber input[type=range] {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
}
.BRcontrols .scrubber input[type=range]:focus {
  outline: none;
}
.BRcontrols .scrubber input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  border: 0px solid rgba(204, 204, 204, 0);
  border: 0;
  border-radius: 8px;
  width: 100%;
  height: 8px;
  cursor: pointer;
}
.BRcontrols .scrubber input[type=range]::-webkit-slider-thumb {
  margin-top: -4px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  -webkit-appearance: none;
}
.BRcontrols .scrubber input[type=range]::-moz-range-track {
  background: #666666;
  border: 0px solid rgba(204, 204, 204, 0);
  border: 0;
  border-radius: 8px;
  width: 100%;
  height: 8px;
  cursor: pointer;
}
.BRcontrols .scrubber input[type=range]::-moz-range-progress {
  height: 8px;
  background: #ccc;
}
.BRcontrols .scrubber input[type=range]::-ms-track {
  border: none;
  color: transparent;
  background: #666;
}
.BRcontrols .scrubber input[type=range]::-ms-fill-lower {
  background: #ccc;
}
.BRcontrols .scrubber input[type=range]::-ms-tooltip {
  display: none;
}
.BRcontrols .scrubber input[type=range]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 8px;
  cursor: pointer;
}
.BRcontrols .scrubber p {
  padding: 0 20px 0 13px;
  margin: 0;
  align-self: stretch;
  font-weight: 700;
  line-height: 26px;
  color: #fff;
}
@media (max-width: 800px) {
  .BRcontrols .scrubber p {
    padding: 0;
  }
}
.BRcontrols .BRnavline {
  top: -4px;
}
.BRcontrols .scrubber .BRpager.ui-slider {
  top: 10px;
  background-color: #666;
}
.BRcontrols .scrubber .BRpager.ui-slider .ui-slider-range {
  background-color: #ccc;
}

.textSelectionSVG {
  pointer-events: none;
}
.textSelectionSVG .BRwordElement {
  pointer-events: all;
}
.textSelectionSVG.selectingSVG {
  pointer-events: auto;
  cursor: text;
}
.textSelectionSVG .BRwordElement::selection {
  background: rgba(86, 158, 230, 0.4);
}
.textSelectionSVG .BRwordElement::-moz-selection {
  background: rgba(86, 158, 230, 0.4);
  color: transparent;
}
.textSelectionSVG .BRparagElement {
  fill: transparent;
  cursor: text;
  white-space: pre;
  font-family: Georgia, serif;
}

.BRpagecontainer img {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/**
 * Hide modal-manager that loads item-navigator-modal 
 * loading bookmarks related alert messages 
 */
#item-navigator-modal {
  display: none;
}

.BRpagecontainer {
  --iconWidth: 16px;
  --iconHeight: 24px;
  --iconFillColor: #000;
}

/* BOOKMARKS - on page */
.BRpagecontainer .bookmark-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  opacity: 0;
}

.BRtwopageview .BRpagecontainer[data-side=L] .bookmark-button {
  right: auto;
  left: 0;
  width: 590px;
}

.BRpagecontainer .bookmark-button.filled {
  opacity: 1;
}

.BRpagecontainer .bookmark-button.default {
  --iconFillColor: var(--defaultBookmarkColor, #eb3223);
}

.BRpagecontainer .bookmark-button.blue {
  --iconFillColor: var(--blueBookmarkColor, #0023f5);
}

.BRpagecontainer .bookmark-button.red {
  --iconFillColor: var(--redBookmarkColor, #eb3223);
}

.BRpagecontainer .bookmark-button.green {
  --iconFillColor: var(--greenBookmarkColor, #75ef4c);
}

.BRreturn{
  display: none;
}

.hide{
  display: none;
}
